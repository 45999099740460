import React, { useRef, useState } from "react";
import { Group } from "./Group";
import { Link } from "react-router-dom";

import Icon from "../components/ChevronIcon";

const Button = ({
	text = null,
	className = null,
	icon = (
		<div>
			<Icon />
		</div>
	),
	to = null,
	type = null,
	disabled = null,
	children = null,
	iconPos = "end",
}) => {
	let tag = "div";
	if (to) tag = Link;
	if (type === "submit") tag = "button";

	return (
		<Group
			wrap={({ className, children }) => {
				let Wrapper = tag;
				return (
					<Wrapper
						className={className}
						to={to}
						type={type}
						disabled={disabled}
					>
						{children}
					</Wrapper>
				);
			}}
			direction="horizonal"
			className={`${className}`}
			footer={iconPos === "end" ? () => <div>{icon}</div> : null}
			header={iconPos === "start" ? () => <div>{icon}</div> : null}
		>
			{children ? children : text}
		</Group>
	);
};

export default Button;
