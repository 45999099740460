function convertToNameCase(str) {
	const words = str.split(/\b/);

	const nameCaseWords = words.map((word) => {
		const lowerCaseWord = word.toLowerCase();

		const capitalizedWord = lowerCaseWord.replace(
			/(?:^|\b)[a-z]/g,
			(match) => match.toUpperCase()
		);

		return capitalizedWord;
	});

	const nameCaseString = nameCaseWords.join("");

	return nameCaseString;
}

export default convertToNameCase;
