import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  NavLink,
  useParams,
  useLocation,
} from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";

import { AppLayoutProvider, AppLayout } from "./layouts/AppLayout";

import ViewportHeightCss from "./components/ViewportHeightCss";

import { GAInitializer } from "./components/GA4";

const requirePageComponents = () => {
  const pages = require.context("./pages", true, /\.js$/);
  return pages.keys().map((key) => ({
    component: pages(key).default,
    fileName: key,
  }));
};

const pageComponents = requirePageComponents();

function TransitionPage({ component }) {
  const Page = component;

  return (
    <motion.div
      className="pt-[5vh] md:pt-[10vh] pb-6 flex flex-grow"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
      transition={{ duration: 0.4 }}
    >
      <Page />
    </motion.div>
  );
}

function App() {
  const location = useLocation();

  return (
    <>
      <GAInitializer trackingIds={[{ trackingId: "G-84PKQY9BXF" }]} />
      <AppLayoutProvider>
        <ViewportHeightCss />
        <AppLayout>
          <AnimatePresence mode="wait" initial={false}>
            <Routes location={location} key={location.pathname}>
              {pageComponents.map(
                ({ component: PageComponent, fileName }, index) => {
                  const pageMetadata = PageComponent.metadata || {};
                  let path =
                    pageMetadata.path ||
                    `/${PageComponent.displayName || ""}`.toLowerCase();

                  const isIndexPage = fileName
                    .toLowerCase()
                    .includes("index.js");
                  path = isIndexPage ? "/" : path;

                  return (
                    <Route
                      key={index}
                      exact={path === "/"}
                      path={path}
                      element={<TransitionPage component={PageComponent} />}
                    />
                  );
                }
              )}
            </Routes>
          </AnimatePresence>
        </AppLayout>
      </AppLayoutProvider>
    </>
  );
}

export default App;
