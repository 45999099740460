import React, { useCallback } from "react";
import Center from "../ui/Center";
import VStack from "../ui/VStack";
import Wings from "../components/WingsIcon";

const Loader = () => {
	return (
		<Center>
			<div className="animate-pulse text-center font-alt -translate-y-[15%]">
				<div className="Svg w-[200px] mb-10">
					<Wings />
				</div>
				<Center axos="x">
					<p className="text-[12px] bg-white text-black shadow-md border-[1px] p-2 px-4 rounded-full">
						Loading
					</p>
				</Center>
			</div>
		</Center>
	);
};

export default Loader;
