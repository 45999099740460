import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../useStore.js";

import { LayoutSlot } from "../layouts/AppLayout";

import Container from "../ui/Container";
import CTA from "../ui/CTA";
import VStack from "../ui/VStack";
import Center from "../ui/Center";

const SearchForm = () => {
	const { firstName, lastName, setFirstName, setLastName, visitorId } =
		useStore();
	const [stateFirstName, setStateFirstName] = useState(firstName);
	const [stateLastName, setStateLastName] = useState(lastName);
	const [isFormValid, setIsFormValid] = useState(firstName && lastName);
	const navigate = useNavigate();

	const handleFirstNameChange = (event) => {
		setStateFirstName(event.target.value);
		checkFormValidity(event.target.value, stateLastName);
	};

	const handleLastNameChange = (event) => {
		setStateLastName(event.target.value);
		checkFormValidity(stateFirstName, event.target.value);
	};

	const checkFormValidity = (firstName, lastName) => {
		setIsFormValid(firstName && lastName);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setFirstName(stateFirstName);
		setLastName(stateLastName);
		// Store the values in the store or perform any necessary actions
		// e.g., make an API call with the search criteria
		// navigate to another page using React Router
		navigate(`/search/?t=${new Date().getTime()}`); // Navigate to the confirmation page
	};

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<Container>
					<VStack>
						<input
							className="py-3 text-2xl bg-transparent"
							placeholder="First Name"
							type="text"
							value={stateFirstName}
							onChange={handleFirstNameChange}
						/>
					</VStack>
				</Container>
				<hr />
				<Container>
					<VStack>
						<input
							className="py-3 text-2xl bg-transparent"
							placeholder="Last Name"
							type="text"
							value={stateLastName}
							onChange={handleLastNameChange}
						/>
						<Center axis="x">
							<CTA type="submit" disabled={!isFormValid}>
								Find Me!
							</CTA>
						</Center>
					</VStack>
				</Container>
			</form>
		</div>
	);
};

export default SearchForm;
