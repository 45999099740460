import React, { useRef, useState, useEffect } from "react";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import { Canvg } from "canvg";
import html2canvas from "html2canvas";

import { Link, useParams } from "react-router-dom";
import useAthleteData from "../utils/useAthleteData.js";

import { IconContext } from "react-icons";

import { GrDocumentPdf, GrDocumentImage, GrShareOption } from "react-icons/gr";

import { LayoutSlot } from "../layouts/AppLayout";

import Loader from "../components/Loader";
import BackButton from "../ui/BackButton";
import Center from "../ui/Center";

import AchievementAllAmerican from "../components/AchievementAllAmerican";
import AchievementNationalChampion from "../components/AchievementNationalChampion";
import AchievementNationalRecord from "../components/AchievementNationalRecord";

import { GAEvent } from "../components/GA4";

import PrintPoster from "../components/posters/PrintPoster";
import StoryPoster from "../components/posters/StoryPoster";
import SquarePoster from "../components/posters/SquarePoster";

import DownloadIcon from "../components/DownloadIcon";
import PageIcon from "../components/PageIcon";
import StoryIcon from "../components/StoryIcon";
import SquareIcon from "../components/SquareIcon";

const posters = [
  {
    width: 1080,
    height: 1920,
    window: 0.46,
    aspect: "Insta/TikTok story",
    action: `share with #runyourway`,
    render: StoryPoster,
    filename: "STORY",
  },
  {
    width: 1080,
    height: 1080,
    window: 0.3,
    aspect: "square social",
    action: `share with #runyourway`,
    render: SquarePoster,
    filename: "SQUARE",
  },
  {
    width: 3600,
    height: 5400,
    window: 0.45,
    aspect: `12" x 18" print-ready`,
    action: `print at home`,
    render: PrintPoster,
    filename: "PRINT",
  },
];

const EventPage = () => {
  const svgRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { bibId, entryIndex } = useParams();

  const { isLoading, athleteData, athleteFound } = useAthleteData(bibId);

  return (
    <div className="w-full">
      <LayoutSlot slot="header">
        <div>
          <BackButton to={`/athlete/${bibId}`}>Back</BackButton>
        </div>
      </LayoutSlot>
      {isLoading ? (
        <Loader />
      ) : athleteFound ? (
        <EventPoster
          firstName={athleteData.FirstName}
          lastName={athleteData.LastName}
          event={athleteData.entries[entryIndex]}
          bibId={bibId}
        />
      ) : (
        <Center>
          <p>No data found.</p>
        </Center>
      )}
    </div>
  );
};

function dateStamp() {
  var date = new Date();

  // Get the individual date components
  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  var day = date.getDate().toString().padStart(2, "0");
  var hours = date.getHours().toString().padStart(2, "0");
  var minutes = date.getMinutes().toString().padStart(2, "0");

  // Generate the date stamp string
  var dateStamp = year + "-" + month + "-" + day + "-" + hours + "-" + minutes;
  return dateStamp;
}

const EventPoster = ({ firstName, lastName, event, bibId }) => {
  const svgRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [layoutIx, setLayoutIx] = useState(0);

  const [pngData, setPngData] = useState("");

  const layout = posters[layoutIx];
  const Poster = layout.render;

  useEffect(() => {
    setPngData(null);
    const generatePngData = async () => {
      const svgElement = svgRef.current;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const svgMarkup = svgElement.innerHTML;
      const v = await Canvg.fromString(ctx, svgMarkup, {
        useCORS: false,
      });
      await v.render();

      const imageData = canvas.toDataURL("image/png");
      setPngData(imageData);
    };

    const timeoutId = setTimeout(() => {
      generatePngData();
    }, 250); // Adjust the delay time as needed

    return () => {
      clearTimeout(timeoutId);
    };
  }, [layoutIx]);

  const handleDownload = async ({ type = "png", ...opts }) => {
    const svgElement = svgRef.current;

    /*await loadFonts([
      "http://localhost:3000/NewBalance.ttf", // Replace with your font URL
    ]);

    html2canvas(svgElement).then(function (canvas) {
      document.body.appendChild(canvas);
    });

    return;*/
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const svgMarkup = svgElement.innerHTML;
    const v = await Canvg.fromString(ctx, svgMarkup, {
      useCORS: false,
    });
    await v.render();

    let imageData;
    let blob;

    let filename = opts.layout.filename || "DOWNLOAD";
    let timestamp = dateStamp();

    let downloadFilename = `NB-${filename}-POSTER-${timestamp}`;

    switch (type) {
      case "pdf":
        imageData = canvas.toDataURL("image/png");

        // Create a new jsPDF instance
        const pdf = new jsPDF({
          orientation: "p",
          unit: "in",
          format: [12, 18],
        });

        // Add the image to the PDF
        pdf.addImage(
          imageData,
          "PNG",
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight()
        );

        // Save the PDF
        blob = pdf.output("blob");
        saveAs(blob, `${downloadFilename}.pdf`);
        setLoading(false);

        break;

      case "png":
      default:
        canvas.toBlob((blob) => {
          if (opts.share && navigator.share) {
            // Create a file object from the image blob
            const file = new File([blob], `${downloadFilename}.png`, {
              type: "image/png",
            });

            // Call the share API with the file object as the share media
            navigator
              .share({
                files: [file],
                text: `#runyourway`,
              })
              .then(() => {
                //console.log("Image shared successfully!");
                setLoading(false);
              })
              .catch((error) => {
                //console.error("Error sharing image:", error);
                saveAs(blob, `${downloadFilename}.png`);
                setLoading(false);
              });
          } else {
            saveAs(blob, `${downloadFilename}.png`);
            setLoading(false);
          }
        });

        break;
    }

    /*
    // Wait for the custom fonts to load
    await loadFonts([
      "/Poppins-Black.ttf", // Replace with your font URL
    ]);

    // Capture the SVG as an image using html2canvas
    const canvas = await html2canvas(svgElement, {
      useCORS: true, // Enable CORS to load external fonts
    });

    // Convert the canvas to a data URL
    const imageData = canvas.toDataURL("image/png");

    // Create a new jsPDF instance
    const pdf = new jsPDF({
      orientation: "l",
      unit: "in",
      format: [18, 12],
    });

    // Add the image to the PDF
    pdf.addImage(
      imageData,
      "PNG",
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      pdf.internal.pageSize.getHeight()
    );

    // Save the PDF
    const blob = pdf.output("blob");
    saveAs(blob, "svg_to_pdf.pdf");*/
  };

  // Helper function to load custom fonts
  const loadFonts = (fontUrls) => {
    const fontPromises = fontUrls.map((fontUrl) => {
      return new Promise((resolve) => {
        const font = new FontFace("New Balance", `url(${fontUrl})`);

        // Wait for the font to load
        font.load().then(() => {
          // Add the loaded font to the document
          document.fonts.add(font);

          resolve();
        });
      });
    });

    return Promise.all(fontPromises);
  };

  return (
    <div
      className="absolute top-0 left-0 right-0 bottom-0"
      style={{ cursor: loading ? "wait" : null }}
    >
      <IconContext.Provider value={{ color: "white" }}>
        <div className="fixed bottom-10 left-1/2 -translate-x-1/2">
          <div className="ml-auto mr-auto relative w-[250px]">
            <div
              className={`z-[1] h-[40px] w-[33%] rounded-[40px] bg-black absolute ${
                layoutIx === 0 ? "left-0" : ""
              } ${layoutIx === 1 ? "left-[33%]" : ""} ${
                layoutIx === 2 ? "left-[67%]" : ""
              } left-0 transition-all`}
            />
            <div
              className="relative z-[2] flex justify-between rounded-[40px] overflow-hidden mb-4"
              style={{ background: "rgba(255,255,255,0.1)" }}
            >
              <div
                className="cursor-pointer flex items-center justify-center h-[40px] w-[33%]"
                onClick={() => setLayoutIx(0)}
              >
                <StoryIcon />
              </div>

              <div
                className="cursor-pointer flex items-center justify-center h-[40px] w-[33%]"
                onClick={() => setLayoutIx(1)}
              >
                <SquareIcon />
              </div>
              <div
                className="cursor-pointer flex items-center justify-center h-[40px] w-[33%]"
                onClick={() => setLayoutIx(2)}
              >
                <PageIcon />
              </div>
            </div>
          </div>

          <p className="pb-4 font-alt text-sm text-center min-w-[300px]">
            Download your {layout.aspect} poster
            <br />
            and {layout.action}
          </p>

          <div className="flex gap-4 z-10 justify-center">
            {/*<button
            className="w-10 h-10 text-center bg-[#222] text-white rounded-full"
            onClick={() => {
              setLoading(true);

              setTimeout(() => {
                handleDownload({ type: "pdf" });
              }, 200);
            }}
            style={{
              pointerEvents: loading ? "none" : null,
              opacity: loading ? 0.5 : null,
            }}
          >
            <GrDocumentPdf className="Icon ml-[auto] mr-[auto]" />
          </button>*/}
            <GAEvent
              category="POSTER BUTTON"
              action={`DOWNLOAD_${layout.filename}`}
              label={`ATHLETE_${bibId}`}
            >
              <button
                className="w-20 h-20 text-center border-2 border-[#CF0A2C] bg-[#151415] shadow-md text-white rounded-full"
                onClick={() => {
                  setLoading(true);

                  setTimeout(() => {
                    handleDownload({ type: "png", layout: layout });
                  }, 200);
                }}
                style={{
                  pointerEvents: loading ? "none" : null,
                  opacity: loading ? 0.5 : null,
                }}
              >
                <DownloadIcon className="ml-auto mr-auto" />
              </button>
            </GAEvent>
            {/*navigator.share && (
              <button
                className="w-20 h-20 text-center border-2 border-[#CF0A2C] bg-[#151415] shadow-md text-white rounded-full"
                onClick={() => {
                  setLoading(true);

                  setTimeout(() => {
                    handleDownload({ type: "png" }, { share: true });
                  }, 200);
                }}
                style={{
                  pointerEvents: loading ? "none" : null,
                  opacity: loading ? 0.5 : null,
                }}
              >
                <GrShareOption className="Icon ml-[auto] mr-[auto]" />
              </button>
            )*/}
          </div>
        </div>
      </IconContext.Provider>

      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: layout.width,
          height: layout.height,
          overflow: "hidden",
          boxShadow: `0 0 200px rgba(0,0,0,0.2)`,
          marginTop: "-90px",
          transform: `translate(-50%,-50%) scale(${Math.min(
            1,
            (window.innerHeight * layout.window) / layout.height
          )})`,
        }}
      >
        {pngData && (
          <GAEvent
            category="POSTER IMAGE"
            listen="CONTEXT_MENU"
            action={`SAVE_${layout.filename}`}
            label={`ATHLETE_${bibId}`}
          >
            <img
              className="opacity-0 block w-full absolute top-0 left-0 z-[5]"
              src={pngData}
              alt={`${firstName} ${lastName} #${bibId}`}
            />
          </GAEvent>
        )}
        <div ref={svgRef}>
          <Poster firstName={firstName} lastName={lastName} event={event} />
        </div>
      </div>
    </div>
  );
};

EventPage.metadata = {
  path: "/event/:bibId/:entryIndex",
  // Add more metadata properties as needed
};

export default EventPage;
