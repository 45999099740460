import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../useStore.js";
import useFetchedData from "../utils/useFetchedData.js";
import nameCase from "../utils/nameCase.js";

import { LayoutSlot } from "../layouts/AppLayout";

import SearchForm from "../components/SearchForm";
import Loader from "../components/Loader";

import Container from "../ui/Container";
import VStack from "../ui/VStack";

import BackButton from "../ui/BackButton";

import ChevronIcon from "../components/ChevronIcon";

const groupArrayByProperty = (array, propertyName) => {
	const uniqueValues = new Set();
	return array.filter((entry) => {
		if (uniqueValues.has(entry[propertyName])) {
			return false; // Skip duplicate entry
		} else {
			uniqueValues.add(entry[propertyName]);
			return true; // Include unique entry
		}
	});
};

const Page = () => {
	const { meetId, firstName, lastName } = useStore();

	const { isLoading, data } = useFetchedData(
		`https://nbn-up57egmlbq-uc.a.run.app/?mid=${meetId}&search=${lastName}`,
		{
			cacheFor: 60,
		}
	);

	let matches = [];
	// Try to match the first name too (API will only check lastname)
	if (data && data.entries && data.entries.length > 0) {
		matches = data.entries.filter((entry) => {
			return entry.FirstName.toLowerCase().match(firstName.toLowerCase());
		});
	}

	return (
		<div className="w-full">
			<LayoutSlot slot="header">
				<div>
					<BackButton to={"/find-me/"}>Back</BackButton>
				</div>
			</LayoutSlot>

			{isLoading ? (
				<Loader />
			) : matches.length ? (
				<div className="w-full">
					<Container>
						<h1 className="max-w-[450px] pb-[0.5em]">
							Here's who we found...
						</h1>
					</Container>
					{groupArrayByProperty(matches, "BIB").map((entry, ix) => (
						<div key={ix} className="relative">
							<Container>
								<Link
									className="block py-3"
									to={`/athlete/${entry.BIB}`}
								>
									<span className="block font-alt text-sm">
										Team: {entry.TeamName}
									</span>
									<span className="block text-2xl opacity-50">
										{nameCase(entry.FirstName)}{" "}
										{nameCase(entry.LastName)}
									</span>
								</Link>
								<div className="pointer-events-none rounded-full border-2 border-black w-10 h-10 flex justify-center items-center absolute right-6 top-1/2 -translate-y-1/2">
									<div className="ml-[2px]">
										<ChevronIcon />
									</div>
								</div>
							</Container>
							<hr />
						</div>
					))}
				</div>
			) : (
				<div className="w-full">
					<Container>
						<VStack>
							<h2 className="max-w-[450px] mb-[1em]">
								Hmm, no athletes found.
								<br />
								Try re-entering your information:
							</h2>
						</VStack>
					</Container>
					<SearchForm />
				</div>
			)}
		</div>
	);
};

Page.metadata = {
	path: "/search/",
	// Add more metadata properties as needed
};

export default Page;
