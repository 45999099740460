import React, { useRef, useState } from "react";
import Button from "./Button";
import { Link } from "react-router-dom";

import Icon from "../components/ChevronIcon";

const CTA = ({
	text = null,
	className = null,
	icon = (
		<div>
			<Icon />
		</div>
	),
	to = null,
	type = null,
	disabled = null,
	children = null,
}) => {
	return (
		<Button
			to={to}
			type={type}
			disabled={disabled}
			className={`text-xl w-full border-2 border-[#CF0A2C] bg-[#151415] shadow-md rounded-[2em] p-4 px-6 pt-5 max-w-[250px] leading-none -ml-[0.5em] ${className}`}
			footer={() => <div>{icon}</div>}
		>
			{children ? children : text}
		</Button>
	);
};

export default CTA;
