import React, { useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import useStore from "../useStore.js";
import useAthleteData from "../utils/useAthleteData.js";
import { LayoutSlot } from "../layouts/AppLayout";

import CTA from "../ui/CTA";
import VStack from "../ui/VStack";
import Container from "../ui/Container";
import FluidBox from "../ui/FluidBox";
import Center from "../ui/Center";
import BackButton from "../ui/BackButton";

import NoResults from "../components/NoResults";
import Loader from "../components/Loader";

const Page = () => {
	const { meetId } = useStore();
	const { bibId } = useParams();

	const { isLoading, athleteData, athleteFound } = useAthleteData(bibId);

	return (
		<>
			<LayoutSlot slot="header">
				<div>
					<BackButton to={"/start/"} text={"Back"} />
				</div>
			</LayoutSlot>
			<Container>
				{isLoading ? (
					<Loader />
				) : athleteFound ? (
					<div className="text-center">
						<VStack>
							<p className="text-3xl">Bib #{bibId} is</p>
							<h1 className="mb-[2em]">
								{athleteData.FirstName}
								<br />
								{athleteData.LastName}
							</h1>

							<Center axis="x">
								<CTA to={`/athlete/${bibId}`}>That's me!</CTA>
							</Center>
							<div className="font-alt text-sm underline opacity-25">
								<Link to={`/find-me/`}>Not you?</Link>
							</div>
						</VStack>
					</div>
				) : (
					<NoResults />
				)}
			</Container>
		</>
	);
};

Page.metadata = {
	path: "/confirm-athlete/:bibId",
	// Add more metadata properties as needed
};

export default Page;
