import React, { useRef, useState } from "react";
import Button from "./Button";
import { Link } from "react-router-dom";

import Icon from "../components/ChevronIcon";

const BackButton = ({
	text = null,
	className = null,
	icon = (
		<div>
			<Icon />
		</div>
	),
	to = null,
	type = null,
	disabled = null,
	children = null,
}) => {
	return (
		<Button
			to={to}
			type={type}
			disabled={disabled}
			className={`text-black bg-[#fff] shadow-md rounded-[2em] p-2 leading-none -ml-[0.5em] inline-flex pr-4 ${className}`}
			icon={
				<div className="-rotate-180 opacity-50">
					<Icon />
				</div>
			}
			iconPos="start"
		>
			{children ? children : text}
		</Button>
	);
};

export default BackButton;
