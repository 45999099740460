import * as React from "react";

function Icon(props) {
	return (
		<>
			<g clipPath="url(#prefix__clip0_144_83)" fill="#fff">
				<path d="M62 98h-2.718v-9.12L55.71 98h-1.178l-3.572-9.12V98h-2.698V85.327h3.781l3.078 7.904 3.078-7.904H62V98zm12.434 0h-8.968V85.327h8.968v2.318h-6.27V90.4h6.137v2.318h-6.137v2.945h6.27V98zm12.028 0h-8.969V85.327h8.969v2.318h-6.27V90.4h6.137v2.318h-6.137v2.945h6.27V98zm8.702 0h-2.717V87.702h-3.705v-2.375H98.85v2.375h-3.686V98zm-51.21 26h-3.098l-2.489-4.541h-1.976V124h-2.698v-12.673h5.928c2.641 0 4.256 1.729 4.256 4.085 0 2.242-1.406 3.439-2.774 3.762l2.85 4.826zm-2.832-8.607c0-1.064-.817-1.748-1.9-1.748h-2.83v3.496h2.83c1.083 0 1.9-.684 1.9-1.748zM55.82 124h-8.968v-12.673h8.968v2.318h-6.27v2.755h6.137v2.318H49.55v2.945h6.27V124zm9.101.228c-3.705 0-6.65-2.66-6.65-6.555 0-3.895 2.945-6.555 6.65-6.555 2.888 0 4.503 1.52 5.396 3.097L68 115.355a3.47 3.47 0 00-3.078-1.843c-2.204 0-3.876 1.767-3.876 4.161 0 2.394 1.672 4.161 3.876 4.161 1.406 0 2.546-.798 3.078-1.843l2.318 1.121c-.912 1.577-2.508 3.116-5.396 3.116zm7.344-6.555c0-3.819 2.793-6.555 6.612-6.555 3.838 0 6.631 2.736 6.631 6.555 0 3.819-2.793 6.555-6.63 6.555-3.82 0-6.613-2.736-6.613-6.555zm10.47 0c0-2.375-1.52-4.161-3.858-4.161-2.337 0-3.838 1.786-3.838 4.161 0 2.356 1.501 4.161 3.838 4.161s3.857-1.805 3.857-4.161zM98.611 124h-3.097l-2.49-4.541H91.05V124h-2.698v-12.673h5.928c2.64 0 4.256 1.729 4.256 4.085 0 2.242-1.406 3.439-2.774 3.762l2.85 4.826zm-2.831-8.607c0-1.064-.817-1.748-1.9-1.748H91.05v3.496h2.83c1.084 0 1.9-.684 1.9-1.748zM106.508 124h-4.997v-12.673h4.997c3.971 0 6.726 2.527 6.726 6.346S110.479 124 106.508 124zm-2.299-10.298v7.923h2.299c2.508 0 3.971-1.805 3.971-3.952 0-2.242-1.368-3.971-3.971-3.971h-2.299z" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M64 50.385V27.536c5.509-2.624 13.416-6.145 17.888-.576 1.75 3.648 18.47.576 18.47.576v22.849c-3.824 1.664-15.165 3.648-17.887 1.536-.7-.543-.761-1.152-.821-1.746-.08-.786-.156-1.547-1.707-2.094-1.8-.635-8.204.7-13.115 1.723-1.026.215-1.988.415-2.828.58zM60 29v40h2V29h-2z"
				/>
			</g>
			<defs>
				<clipPath id="prefix__clip0_144_83">
					<path fill="#fff" d="M0 0h146v146H0z" />
				</clipPath>
			</defs>
		</>
	);
}

export default Icon;
