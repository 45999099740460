const cache = {};

const cachedFetch = async (url, { cacheFor = 60, onComplete }) => {
	if (cacheFor > 0 && cache[url] && cache[url].expiry > Date.now()) {
		return cache[url].response;
	}

	const response = await fetch(url);
	const responseData = await response.json();

	if (onComplete) onComplete(responseData);

	if (cacheFor > 0) {
		cache[url] = {
			response: responseData,
			expiry: Date.now() + cacheFor * 1000, // Convert cacheFor seconds to milliseconds
		};
	}

	return responseData;
};

export default cachedFetch;
