import React, { useRef, useState } from "react";

const Container = ({ children, className = "" }) => {
	return (
		<div
			className={`relative w-full px-6 max-w-4xl ml-auto mr-auto ${className}`}
		>
			{children}
		</div>
	);
};

export default Container;
