import * as React from "react";

function Icon(props) {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 24 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 2h2c.921 0 1.827.07 2.71.203l-.09.222c1.12.461 2.28 1.607 2.723 3.358.44 1.732.2 4.162-1.691 7.187l-.956 1.53H21.144A17.99 17.99 0 0122 20v10H2V2zm18.368 10.5a18.061 18.061 0 00-9.993-9.338 7.42 7.42 0 01.907 2.13c.51 2.01.283 4.458-1.077 7.208H20.368zM0 0h4c11.046 0 20 8.954 20 20v12H0V0z"
				fill="#fff"
			/>
		</svg>
	);
}

export default Icon;
