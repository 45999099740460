import React, { useRef, useState } from "react";

const Center = ({ axis = "xy", children, className = "" }) => {
	return (
		<div
			className={`flex ${
				["x", "xy"].includes(axis) ? "justify-center" : ""
			} ${
				["y", "xy"].includes(axis) ? "items-center" : ""
			} h-full ${className}`}
		>
			{children}
		</div>
	);
};

export default Center;
