import AchievementAllAmerican from "../../components/AchievementAllAmerican";
import AchievementNationalChampion from "../../components/AchievementNationalChampion";
import AchievementNationalRecord from "../../components/AchievementNationalRecord";

import AchievementMeetRecord from "../../components/AchievementMeetRecord";
import AchievementHighSchool from "../../components/AchievementHighSchool";
import AchievementFranklinField from "../../components/AchievementFranklinField";
import AchievementU20 from "../../components/AchievementU20";

const AchievmentIcons = {
	AA: AchievementAllAmerican,
	NC: AchievementNationalChampion,
	NR: AchievementNationalRecord,
	HS: AchievementHighSchool,
	MR: AchievementMeetRecord,
	FF: AchievementFranklinField,
	U20: AchievementU20,
};

export default function AchievementIcons({ achievements, w = 1080, y = 0 }) {
	return achievements.map((com, ix) => {
		let Icon = AchievmentIcons[com];
		if (!Icon) return null;

		let top = y;
		let scale = Math.floor(w / 1080);

		let margin = 40 * scale;
		let gutter = -5 * scale * (ix + 1);
		let transform = 146 * scale;
		let pos = 146 * scale * ix;

		return (
			<g
				key={`icon${ix}`}
				transform={`translate(${
					w - pos - transform - gutter - margin
				},${top})`}
			>
				<g transform={`scale(${scale})`}>
					<Icon />
				</g>
			</g>
		);
	});
}
