import * as React from "react";
import * as ReactDOM from "react-dom";

// context contains all slots
export const SlotContext = React.createContext({});

const initialSlotState = {};
const slotReducer = (state, action) => {
	switch (action.type) {
		case "update_slot_ref": {
			const { slotName, ref } = action;
			return {
				...state,
				[slotName]: ref,
			};
		}
		default:
			throw new Error();
	}
};

// this keeps state + context related logic in one single place (here)
export const SlotProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(slotReducer, initialSlotState);
	return (
		<SlotContext.Provider value={{ state, dispatch }}>
			{children}
		</SlotContext.Provider>
	);
};

// the logic to retrieve partial state from the conceptual "slot" state
export const useSlot = (slotName) => {
	const { state, dispatch } = React.useContext(SlotContext);
	return [
		state[slotName],
		(ref) => {
			dispatch({ type: "update_slot_ref", ref, slotName });
		},
	];
};

// maintain all slot ids here?
export const SLOTS = {
	TOP_NAV: "top-nav",
};

export const SlotPortal = ({ slotName, children }) => {
	const [slotRef] = useSlot(slotName);

	return slotRef ? ReactDOM.createPortal(children, slotRef) : null;
};

export const SlotHome = ({ slotName, className }) => {
	const [, setSlot] = useSlot(slotName);
	const setSlotMemo = React.useCallback(setSlot, [slotName]);
	/**
	 * this is a callback ref https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
	 * to differentiate from normal ref, its need is explained in this section
	 * https://reactjs.org/docs/hooks-reference.html#useref
	 */
	const ref = React.useCallback(
		(node) => {
			setSlotMemo(node);
		},
		[setSlotMemo]
	);
	return <div className={className} ref={ref} />;
};
