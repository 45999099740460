import { create } from "zustand";
import nameCase from "./utils/nameCase";

/*

Meet IDs:

2225 - DEMO
5441 - NBNI
5697 - NBNO

*/

const useStore = create((set) => ({
	meetId: 5697, // 2225
	visitorId: "",
	visitorData: null,
	setVisitorId: (id) => set(() => ({ visitorId: id })),
	setVisitorData: (data) => set(() => ({ visitorData: data })),
	firstName: "",
	lastName: "",
	setFirstName: (firstName) => set(() => ({ firstName })),
	setLastName: (lastName) => set(() => ({ lastName })),
	athleteData: {},
	setMeetId: (meetId) => set({ meetId }),
	setAthleteData: ({ bibId, data }) => {
		// Transform API response for Bib ID into an object for the athlete
		if (!data.entries) return;
		if (!data.entries.length) return;

		return set((state) => ({
			athleteData: {
				...state.athleteData,

				[bibId]: {
					...data.entries[0],
					FirstName: nameCase(data.entries[0].FirstName),
					LastName: nameCase(data.entries[0].LastName),
					entries: data.entries
						.filter((e) => e.Mark)
						.map((e) =>
							e.TeamName.toLowerCase() === "unattached"
								? { ...e, TeamName: "" }
								: e
						)
						.map((e) => {
							/*
								Real Example: event/1629/2/
							*/

							let achievements = [];
							if (e.Records) {
								// High School Record
								if (e.Records.includes("HS"))
									achievements.push("HS");

								// National Record
								if (e.Records.includes("NR"))
									achievements.push("NR");

								// Meet Record
								if (e.Records.includes("MR"))
									achievements.push("MR");

								// Franklin Field Record
								if (e.Records.includes("FF"))
									achievements.push("FF");

								// World U20 Record
								if (e.Records.includes("U20"))
									achievements.push("U20");
							}

							// - National Champion — 'Round' is “Final” 'EventName' includes the word "Championship", 'Status' is “Complete” and 'Place' is 1
							if (
								e.Round.toLowerCase() === "final" &&
								e.EventName.toLowerCase().match(
									"championship"
								) &&
								e.Status.toLowerCase() === "complete" &&
								e.Place === 1
							) {
								achievements.push("NC");
							}
							// - All American — 'Round' is “Final” 'EventName' includes the word "Championship", 'Status' is “Complete” and 'Place' is less than or equal to 6
							if (
								e.Round.toLowerCase() === "final" &&
								e.EventName.toLowerCase().match(
									"championship"
								) &&
								e.Status.toLowerCase() === "complete" &&
								e.Place <= 6
							) {
								achievements.push("AA");
							}

							return { ...e, achievements: achievements };
						})
						.reverse(),
				},
			},
		}));
	},
}));

export default useStore;
