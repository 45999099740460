import React, { useRef, useState } from "react";

const VStack = ({ children, large = false }) => {
	return (
		<div className={`flex flex-col ${large ? "gap-10" : "gap-4"}`}>
			{children}
		</div>
	);
};

export default VStack;
