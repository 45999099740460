import React from "react";

import { SlotHome, SlotProvider, SlotPortal } from "./Slots";

/*

<div>
  <HudOutput>
    <div>I am portalled to the HUD!</div>
  </HudOutput>
  <div>
    I am in the rendered element
   </div>
</div>

*/

export const Slot = ({ name, children }) => {
	return <SlotPortal slotName={name}>{children}</SlotPortal>;
};

const OutputSlot = ({ name }) => {
	return <SlotHome slotName={name} />;
};

export const Group = ({
	direction = "horizontal",
	className = "",
	children,
	header = null,
	footer = null,
	wrap = null,
}) => {
	let Wrapper = wrap ? wrap : "div";

	return (
		<SlotProvider>
			<Wrapper
				className={`relative flex gap-4 justify-between ${
					direction === "vertical" ? "flex-col" : "flex-row"
				} ${className}`}
			>
				{header && <div>{header()}</div>}

				<div className="">{children}</div>

				{footer && <div>{footer()}</div>}
			</Wrapper>
		</SlotProvider>
	);
};

export default Group;
