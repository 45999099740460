import { useEffect } from "react";

const ViewportHeightCss = () => {
	useEffect(() => {
		const setHeightVariables = () => {
			if (window.matchMedia("(pointer: fine)").matches) {
				document.documentElement.style.setProperty(
					"--window-height",
					`${window.innerHeight}px`
				);
			}
		};

		// Store window height on resize for mouse devices
		if (window.matchMedia("(pointer: fine)").matches) {
			window.addEventListener("resize", setHeightVariables);
		}

		// Store viewport height once and on orientation change
		let timeoutId;
		const setViewportHeight = () => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				document.documentElement.style.setProperty(
					"--window-height",
					`${window.innerHeight}px`
				);
			}, 100);
		};
		setViewportHeight();
		setHeightVariables();
		window.addEventListener("orientationchange", setViewportHeight);

		return () => {
			window.removeEventListener("resize", setHeightVariables);
			window.removeEventListener("orientationchange", setViewportHeight);
		};
	}, []);

	return null;
};

export default ViewportHeightCss;
