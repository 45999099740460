import * as React from "react";

function Icon(props) {
	return (
		<>
			<g clipPath="url(#prefix__clip0_141_21)" fill="#fff">
				<path d="M73.001 19l6.362 19.576h20.585L83.294 50.675l6.362 19.576L73 58.152 56.347 70.251l6.362-19.576-16.654-12.099H66.64L73.001 19zM68.622 98.342h-3.1l-.84-2.3h-5.96l-.84 2.3h-3.12l5.16-13.34h3.56l5.14 13.34zm-4.72-4.76l-2.2-6.12-2.22 6.12h4.42zm15.432 4.76h-8.48v-13.34h2.84v10.84h5.64v2.5zm11.469 0h-8.48v-13.34h2.84v10.84h5.64v2.5zm-58.685 26h-3.1l-.84-2.3h-5.96l-.84 2.3h-3.12l5.16-13.34h3.56l5.14 13.34zm-4.72-4.76l-2.2-6.12-2.22 6.12h4.42zm21.412 4.76h-2.86v-9.6l-3.76 9.6h-1.24l-3.76-9.6v9.6h-2.84v-13.34h3.98l3.24 8.32 3.24-8.32h4v13.34zm13.09 0h-9.44v-13.34h9.44v2.44h-6.6v2.9h6.46v2.44H55.3v3.1h6.6v2.46zm14.02 0h-3.26l-2.62-4.78h-2.08v4.78h-2.84v-13.34h6.24c2.78 0 4.48 1.82 4.48 4.3 0 2.36-1.48 3.62-2.92 3.96l3 5.08zm-2.98-9.06c0-1.12-.86-1.84-2-1.84h-2.98v3.68h2.98c1.14 0 2-.72 2-1.84zm8.871 9.06h-2.84v-13.34h2.84v13.34zm10.008.24c-3.9 0-7-2.8-7-6.9 0-4.1 3.1-6.9 7-6.9 3.04 0 4.74 1.6 5.68 3.26l-2.44 1.2a3.653 3.653 0 00-3.24-1.94c-2.32 0-4.08 1.86-4.08 4.38 0 2.52 1.76 4.38 4.08 4.38 1.48 0 2.68-.84 3.24-1.94l2.44 1.18c-.96 1.66-2.64 3.28-5.68 3.28zm20.674-.24h-3.1l-.84-2.3h-5.96l-.84 2.3h-3.12l5.16-13.34h3.56l5.14 13.34zm-4.72-4.76l-2.2-6.12-2.22 6.12h4.42zm18.892 4.76h-2.74l-6.36-8.96v8.96h-2.84v-13.34h2.92l6.18 8.64v-8.64h2.84v13.34z" />
			</g>
			<defs>
				<clipPath id="prefix__clip0_141_21">
					<path fill="#fff" d="M0 0h146v146H0z" />
				</clipPath>
			</defs>
		</>
	);
}

export default Icon;
