import React, { useEffect, useRef } from "react";
import ReactGA from "react-ga4";

import { useLocation } from "react-router-dom";

export const GAInitializer = ({ trackingIds }) => {
	let location = useLocation();

	useEffect(() => {
		ReactGA.initialize(trackingIds);
	}, [trackingIds]);

	React.useEffect(() => {
		ReactGA.ga("send", "pageview");
	}, [location]);

	return null;
};

export const GAEvent = ({
	category,
	children,
	action,
	label,
	value,
	listen = "CLICK",
}) => {
	const containerRef = useRef(null);

	const handleClick = (event) => {
		ReactGA.gtag("event", category, {
			action,
			label,
			value,
		});
	};

	useEffect(() => {
		const container = containerRef.current;
		if (container) {
			if (listen.includes("CLICK"))
				container.addEventListener("click", handleClick);
			if (listen.includes("CONTEXT_MENU")) {
				container.addEventListener("touchstart", handleClick);
				container.addEventListener("contextmenu", handleClick);
			}

			return () => {
				if (listen.includes("CLICK"))
					container.removeEventListener("click", handleClick);
				if (listen.includes("CONTEXT_MENU")) {
					container.removeEventListener("touchstart", handleClick);
					container.removeEventListener("contextmenu", handleClick);
				}
			};
		}
	}, [category, action, label, value]);

	return <div ref={containerRef}>{children}</div>;
};
