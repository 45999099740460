import React from "react";

import { SlotHome, SlotProvider, SlotPortal } from "../ui/Slots";

import { motion, AnimatePresence } from "framer-motion";

import Container from "../ui/Container";

/*

<div>
  <HudOutput>
    <div>I am portalled to the HUD!</div>
  </HudOutput>
  <div>
    I am in the rendered element
   </div>
</div>

*/

export const LayoutSlot = ({ slot, children }) => {
	return <SlotPortal slotName={slot}>{children}</SlotPortal>;
};

const Slot = ({ name }) => {
	return <SlotHome slotName={name} />;
};

export const AppLayoutProvider = ({ children }) => {
	return <SlotProvider>{children}</SlotProvider>;
};

const Presence = ({ visible, children }) => {
	return (
		<AnimatePresence>
			{visible && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 1 }}
				>
					{children}
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export const AppLayout = ({ children }) => {
	return (
		<div id="layout" className="flex flex-col h-viewport">
			<header className="py-6 font-alt">
				<Container>
					<Slot name="header" />
				</Container>
			</header>

			<main className="relative flex flex-grow">{children}</main>

			<footer className="py-6">
				<Container>
					<Slot name="footer" />
				</Container>
			</footer>
		</div>
	);
};

export default AppLayout;
