import { useEffect, useState } from "react";
import useStore from "../useStore.js";
import useFetchedData from "./useFetchedData.js";

const useAthleteData = (bibId, cacheFor = 60) => {
	const { athleteData, setAthleteData, meetId } = useStore();

	const { isLoading } = useFetchedData(
		`https://nbn-up57egmlbq-uc.a.run.app/?mid=${meetId}&search=${bibId}`,
		{
			cacheFor: 60,
			onComplete: (data) => {
				setAthleteData({
					bibId,
					data: data,
				});
			},
		}
	);

	return {
		athleteData: athleteData[bibId] ? athleteData[bibId] : null,
		athleteFound: athleteData[bibId]
			? athleteData[bibId].entries &&
			  athleteData[bibId].entries.length > 0
			: false,
		isLoading,
	};
};

export default useAthleteData;
