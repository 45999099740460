import React, { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useStore from "../useStore.js";
import useAthleteData from "../utils/useAthleteData.js";
import { LayoutSlot } from "../layouts/AppLayout";

import CTA from "../ui/CTA";
import VStack from "../ui/VStack";
import Container from "../ui/Container";
import FluidBox from "../ui/FluidBox";
import Center from "../ui/Center";

import NoResults from "../components/NoResults";
import Loader from "../components/Loader";
import ChevronIcon from "../components/ChevronIcon";
import AchievementIcons from "../components/posters/AchievementIcons";

import BackButton from "../ui/BackButton";

const Page = () => {
	const { visitorId } = useStore();
	const { bibId } = useParams();
	const { isLoading, athleteData, athleteFound } = useAthleteData(bibId);

	return (
		<div className="w-full">
			<LayoutSlot slot="header">
				<div>
					{visitorId ? (
						<BackButton to={`/confirm-athlete/${visitorId}/`}>
							Back
						</BackButton>
					) : (
						<BackButton to={"/"}>Start Again</BackButton>
					)}
				</div>
			</LayoutSlot>

			{isLoading ? (
				<Loader />
			) : athleteFound ? (
				<div className="w-full">
					<Container>
						<h1 className="pb-[0.5em]">
							{athleteData.FirstName}
							<br />
							{athleteData.LastName}
						</h1>
						<div className="font-alt text-sm pb-3 opacity-30">
							Your Events
						</div>
					</Container>
					<hr />
					{athleteData.entries.map((entry, ix) => (
						<div key={ix} className="relative bg-[#151515]">
							<Container>
								<Link
									className="block relative py-3"
									to={`/event/${bibId}/${ix}/`}
								>
									<span className="block font-alt text-sm">
										{entry.Round}
									</span>
									<span className="block text-2xl opacity-50">
										{entry.EventName}
									</span>
									<span
										style={{
											background: `rgba(255, 255, 255, 0.1)`,
										}}
										className="-ml-[0.2em] inline-block p-[2px] px-[5px] rounded-full font-alt text-sm opacity-90"
									>
										{entry.Mark}
									</span>
									{!!entry.achievements.length && (
										<span className="block">
											<div className="absolute right-10 bottom-[5px] md:bottom-[25%] w-[250px] md:w-[300px]">
												<svg viewBox="0 0 1080 150">
													<AchievementIcons
														achievements={
															entry.achievements
														}
														w={1080}
														y={0}
													/>
												</svg>
											</div>
										</span>
									)}
								</Link>
								<div className="pointer-events-none rounded-full border-2 border-currentColor w-10 h-10 flex justify-center items-center absolute right-6 top-1/2 -translate-y-1/2">
									<div className="ml-[2px]">
										<ChevronIcon />
									</div>
								</div>
							</Container>
							<hr />
						</div>
					))}
				</div>
			) : (
				<Container>
					<NoResults />
				</Container>
			)}
		</div>
	);
};

Page.metadata = {
	path: "/athlete/:bibId",
	// Add more metadata properties as needed
};

export default Page;
