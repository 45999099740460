import * as React from "react";

function Icon(props) {
	return (
		<svg
			width={19}
			height={19}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M7.125 0v7.125h-4.75L9.5 14.25l7.125-7.125h-4.75V0h-4.75zM0 16.625V19h19v-2.375H0z"
				fill="#fff"
			/>
		</svg>
	);
}

export default Icon;
