import * as React from "react";

function Icon(props) {
	return (
		<>
			<g clipPath="url(#prefix__clip0_144_66)" fill="#fff">
				<path d="M61.054 98.342h-2.717v-5.32h-5.985v5.32h-2.698V85.669h2.698v4.978h5.985v-4.978h2.717v12.673zm6.145 0h-2.698V85.669H67.2v12.673zm2.858-6.327c0-4.01 3.04-6.555 6.745-6.555 2.622 0 4.275 1.273 5.244 2.774l-2.242 1.216a3.596 3.596 0 00-3.002-1.596c-2.299 0-3.97 1.767-3.97 4.16 0 2.395 1.671 4.162 3.97 4.162 1.16 0 2.185-.475 2.736-.97v-1.538h-3.382V91.35h6.042v4.845c-1.292 1.444-3.097 2.394-5.396 2.394-3.705 0-6.745-2.584-6.745-6.574zm26.633 6.327h-2.717v-5.32h-5.985v5.32H85.29V85.669h2.698v4.978h5.985v-4.978h2.717v12.673zm-64.186 24.214l1.482-2.052a5.556 5.556 0 004.028 1.71c1.482 0 2.204-.703 2.204-1.425 0-.95-1.102-1.273-2.565-1.615-2.071-.475-4.731-1.045-4.731-3.876 0-2.109 1.824-3.819 4.807-3.819 2.014 0 3.686.608 4.94 1.767l-1.501 1.976c-1.026-.95-2.394-1.387-3.63-1.387-1.215 0-1.861.532-1.861 1.292 0 .855 1.064 1.121 2.527 1.463 2.09.475 4.75 1.102 4.75 3.914 0 2.318-1.653 4.066-5.073 4.066-2.432 0-4.18-.817-5.377-2.014zm19.258 2.014c-3.705 0-6.65-2.66-6.65-6.555 0-3.895 2.945-6.555 6.65-6.555 2.888 0 4.503 1.52 5.396 3.097l-2.318 1.14a3.47 3.47 0 00-3.078-1.843c-2.204 0-3.876 1.767-3.876 4.161 0 2.394 1.672 4.161 3.876 4.161 1.406 0 2.546-.798 3.078-1.843l2.318 1.121c-.912 1.577-2.508 3.116-5.396 3.116zm19.352-.228h-2.717v-5.32h-5.985v5.32h-2.698v-12.673h2.698v4.978h5.985v-4.978h2.717v12.673zm2.84-6.327c0-3.819 2.793-6.555 6.612-6.555 3.838 0 6.63 2.736 6.63 6.555 0 3.819-2.792 6.555-6.63 6.555-3.82 0-6.612-2.736-6.612-6.555zm10.469 0c0-2.375-1.52-4.161-3.857-4.161s-3.838 1.786-3.838 4.161c0 2.356 1.5 4.161 3.838 4.161 2.337 0 3.857-1.805 3.857-4.161zm5.01 0c0-3.819 2.792-6.555 6.611-6.555 3.838 0 6.631 2.736 6.631 6.555 0 3.819-2.793 6.555-6.63 6.555-3.82 0-6.613-2.736-6.613-6.555zm10.468 0c0-2.375-1.52-4.161-3.857-4.161s-3.838 1.786-3.838 4.161c0 2.356 1.501 4.161 3.838 4.161s3.857-1.805 3.857-4.161zm13.673 6.327h-8.056v-12.673h2.698v10.298h5.358v2.375z" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M77.12 20.893L73.376 16l-3.743 4.893-5.409-2.948-1.428 5.993-6.141-.493 1.132 6.055-5.81 2.047 3.497 5.071L51 40.852l5.258 3.21-2.367 5.688 6.11.794.15 6.159 5.904-1.76 2.644 5.565 4.678-4.008 4.678 4.008 2.643-5.564 5.904 1.759.151-6.159 6.11-.794-2.367-5.688 5.258-3.21-4.476-4.233 3.497-5.072-5.81-2.047 1.132-6.055-6.14.493-1.429-5.993-5.409 2.948zM84.876 38.5c0 6.351-5.149 11.5-11.5 11.5-6.352 0-11.5-5.149-11.5-11.5S67.025 27 73.377 27c6.351 0 11.5 5.149 11.5 11.5zm2 0c0 7.456-6.044 13.5-13.5 13.5s-13.5-6.044-13.5-13.5S65.92 25 73.377 25s13.5 6.044 13.5 13.5z"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M80 53H67v18h.195l6.304-9.333L79.804 71H80V53z"
				/>
			</g>
			<defs>
				<clipPath id="prefix__clip0_144_66">
					<path fill="#fff" d="M0 0h146v146H0z" />
				</clipPath>
			</defs>
		</>
	);
}

export default Icon;
