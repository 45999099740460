import * as React from "react";

function Icon(props) {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 20 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path stroke="#fff" strokeWidth={2} d="M1 1h18v30H1z" />
		</svg>
	);
}

export default Icon;
