import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { LayoutSlot } from "../layouts/AppLayout";
import useStore from "../useStore.js";

import AppIcon from "../components/WingsIcon";

import CTA from "../ui/CTA";
import VStack from "../ui/VStack";
import Container from "../ui/Container";
import FluidBox from "../ui/FluidBox";
import Center from "../ui/Center";

const BibNumberPage = () => {
	const visitorId = useStore((state) => state.visitorId);
	const [inputId, setInputId] = useState(visitorId);
	const setVisitorId = useStore((state) => state.setVisitorId);
	const navigate = useNavigate();

	const handleFormSubmit = (e) => {
		e.preventDefault();
		setVisitorId(inputId);
		navigate(`/confirm-athlete/${inputId}`); // Navigate to the confirmation page
	};

	return (
		<>
			<LayoutSlot slot="header">
				<div>
					<Link to={"/"}>My Time</Link>
				</div>
			</LayoutSlot>
			<Container>
				<VStack>
					<h2 className="mb-[1em] max-w-[500px]">
						First up, we need your bib number to get your results...
					</h2>
					<div className="font-alt text-sm text-center opacity-30">
						Enter your bib number:
					</div>
					<form onSubmit={handleFormSubmit}>
						<VStack>
							<Center axis="x">
								<div>
									<input
										className="opacity-100 mb-[0.5em] block leading-none px-4 pt-2 rounded-[10rem] text-center max-w-[250px] text-[4rem]"
										placeholder="0000"
										type="text"
										value={inputId}
										onChange={(e) =>
											setInputId(e.target.value)
										}
									/>
								</div>
							</Center>
							<Center>
								<CTA
									text="Next"
									type="submit"
									disabled={!inputId}
								/>
							</Center>
							<Center>
								<div className="font-alt text-sm underline opacity-25">
									<Link to={`/find-me/`}>
										Don't know your bib number?
									</Link>
								</div>
							</Center>
						</VStack>
					</form>
				</VStack>
			</Container>
			<LayoutSlot slot="footer">
				<Center axis="x" className="pb-4">
					<div className="Svg w-[200px]">
						<AppIcon />
					</div>
				</Center>
			</LayoutSlot>
		</>
	);
};

BibNumberPage.metadata = {
	path: "/start/",
	// Add more metadata properties as needed
};

export default BibNumberPage;
