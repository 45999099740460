import React, { useCallback } from "react";
import VStack from "../ui/VStack";

const NoResults = () => {
	return (
		<VStack>
			<h1 className="max-w-[450px]">
				Hmm...
				<br />
				no results found?
			</h1>
			<p className="text-xl max-w-[500px]">
				We couldn't find results for that Bib number.
				<br />
				Maybe the results are still coming in, try again in a minute!
			</p>
		</VStack>
	);
};

export default NoResults;
