import { useEffect, useState } from "react";
import cachedFetch from "./cachedFetch.js";

const useFetchedData = (url, { cacheFor = 60, onComplete } = {}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await cachedFetch(url, {
					cacheFor: cacheFor,
					onComplete: (response) => {
						// This only fires if when the cache expires or on first time
						if (onComplete && typeof onComplete === "function") {
							onComplete(response);
						}
					},
				});

				setData(response);
				setIsLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [url, cacheFor, onComplete]);

	return { isLoading, data };
};

export default useFetchedData;
