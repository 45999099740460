import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../useStore.js";

import { LayoutSlot } from "../layouts/AppLayout";

import Container from "../ui/Container";
import SearchForm from "../components/SearchForm";

import BackButton from "../ui/BackButton";

const Page = () => {
	const visitorId = useStore((state) => state.visitorId);
	return (
		<div className="w-full">
			<LayoutSlot slot="header">
				<div>
					{visitorId ? (
						<BackButton to={`/confirm-athlete/${visitorId}/`}>
							Back
						</BackButton>
					) : (
						<BackButton to={"/"}>Start Again</BackButton>
					)}
				</div>
			</LayoutSlot>
			<Container>
				<h1 className="max-w-[200px] md:max-w-[300px] mb-[1em]">
					Let's try to find you...
				</h1>
			</Container>
			<SearchForm />
		</div>
	);
};

Page.metadata = {
	path: "/find-me/",
	// Add more metadata properties as needed
};

export default Page;
