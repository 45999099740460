import * as React from "react";

function Icon(props) {
	return (
		<svg
			width={12}
			height={17}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M5.499 7.88L.707 3.086l2.38-2.38 7.526 7.526-7.526 7.525-2.38-2.38L5.5 8.586l.354-.353-.354-.354z"
				fill="#fff"
				stroke="#000"
			/>
		</svg>
	);
}

export default Icon;
