import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { LayoutSlot } from "../layouts/AppLayout";

import AppIcon from "../components/WingsIcon";

import CTA from "../ui/CTA";
import VStack from "../ui/VStack";
import Container from "../ui/Container";
import FluidBox from "../ui/FluidBox";
import Center from "../ui/Center";

const StartPage = () => {
	return (
		<>
			<LayoutSlot slot="header">My Time</LayoutSlot>

			<Container>
				<VStack large>
					<section>
						<h1 className="mb-[0.25em]">
							Smashed it.
							<br />
							Share your time.
						</h1>
						<p className="font-alt">Download your social posters</p>
					</section>

					<div className="relative pt-[20vh]">
						<Center axis="x">
							<CTA to="/start/" text="Start" />
						</Center>
					</div>
				</VStack>
			</Container>

			<LayoutSlot slot="footer">
				<Center axis="x" className="pb-4">
					<div className="Svg w-[200px]">
						<AppIcon />
					</div>
				</Center>
			</LayoutSlot>
		</>
	);
};

StartPage.metadata = {
	path: "/",
	// Add more metadata properties as needed
};

export default StartPage;
